
import { siteNumber, getActiveList } from '@/api/home'
import { defineComponent, onMounted, ref, inject, Ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import SwiperCore, { Autoplay, Navigation } from 'swiper/core'
SwiperCore.use([Autoplay, Navigation])
export default defineComponent({
  setup() {
    const news = ref({})
    const activeList = ref([])
    const hotList = ref([])
    const router = useRouter()
    const route = useRoute()

    const active = ref(0)
    const base = ref()
    const banner = ref()
    const newsList = ref([])
    const tel = ref()
    const orderStatus = inject('orderStatus') as Ref
    const applyStatus = inject('applyStatus') as Ref
    const amountStatus = inject('amountStatus') as Ref
    const isFixed = ref(false)
    const id = String(router.currentRoute.value.query.id)
    onMounted(async () => {
      getHotListFunc()
      getActiveListFunc()
      getNewNumber()
      window.addEventListener('scroll', handleScroll)
    })
    const jump = (index:number) => {
      const dd = document.querySelector(`#d${index + 1}`) as any
      window.scrollTo({
        top: dd.offsetTop,
        behavior: 'smooth'
      })
    }
    const handleScroll = () => {
      try {
        const refList = [base.value, banner.value, newsList.value, tel.value]
        const baseTop = base.value.getBoundingClientRect().top
        if (baseTop <= 0) {
          isFixed.value = true
        } else {
          isFixed.value = false
        }
        for (let i = refList.length - 1; i >= 0; i--) {
          if (refList[i] && refList[i].getBoundingClientRect()) {
            const yearsDomTop = refList[i].getBoundingClientRect().top
            if (yearsDomTop < 150) {
              console.log(i)
              active.value = i
              break
            }
          }
        }
      } catch {}
    }
    const openDialog = (e: string) => {
      if (e === 'order') {
        orderStatus.value = true
      } else if (e === 'apply') {
        applyStatus.value = true
      } else if (e === 'amount') {
        amountStatus.value = true
      }
    }
    const next = (type: string, id: string) => {
      if (type === 'type_02') {
        router.push(`/active/detail?id=${id}`)
      } else {
        router.push(`/site/detail?id=${id}`)
      }
    }
    const getNewNumber = async () => {
      const params = { id }
      const data = await siteNumber(params)
      const result = data.data
      result.introduction = '<p>' + result.introduction.replace(/\n/g, '</p><p>').replace(/\s/g, '&nbsp;') + '</p>'
      result.incubationResults = '<p>' + result.incubationResults.replace(/\n/g, '</p><p>').replace(/\s/g, '&nbsp;') + '</p>'
      result.focusArea = '<p>' + result.focusArea.replace(/\n/g, '</p><p>').replace(/\s/g, '&nbsp;') + '</p>'
      result.supportServices = '<p>' + result.supportServices.replace(/\n/g, '</p><p>').replace(/\s/g, '&nbsp;') + '</p>'
      news.value = result
      // hotList.value = data.data.hotNews
    }
    const params = {
      type: 'ht',
      page: 1,
      size: 10,
      siteId: route.query.id
    }
    const paramsHot = {
      type: 'type_02',
      page: 1,
      size: 10,
      siteId: route.query.id
    }
    // 活动
    const getActiveListFunc = async () => {
      const data = await getActiveList(params)
      activeList.value = data.data.records.slice(0, 2)
    }
    // 热门
    const getHotListFunc = async () => {
      const data = await getActiveList(paramsHot)
      hotList.value = data.data.records.slice(0, 2)
    }
    return {
      openDialog,
      next,
      isFixed,
      base,
      banner,
      newsList,
      tel,
      activeList,
      hotList,
      news,
      id,
      navList: ref(['空间介绍', '场地展示', '本地资讯', '联系我们']),
      active,
      jump
    }
  }
})
